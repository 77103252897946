import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold, StrongWhite } from "../../../components/Textstyles/Styles";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 64",
  week: "Semana 10",
  day: "16",
  month: "may",
  monthNumber: "05",
  date: "2020-05-16",
  path: "/cronologia/semana-10#dia-16-may/",
};
const Day64 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="es"
          data="casosFallecidosDiario"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.138 nuevos positivos y 104 personas fallecidas.
          Permanecen hospitalizadas 124.450 personas, -121 menos que el día
          anterior, y 1.627 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.463, lo que supone una disminución de -30 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 53,0 %.
        </ModText>
        <ModDailyHighlight>
          <StrongGold>España</StrongGold> registra el{" "}
          <StrongWhite>menor número de muertes diarias </StrongWhite>
          desde el inicio del confinamiento
        </ModDailyHighlight>
        <ModText>
          España ha realizado casi <strong>dos millones de test PCR</strong>{" "}
          desde el inicio de la epidemia por COVID-19, y ha aumentado su
          capacidad diagnóstica un 18% en los últimos siete días.
        </ModText>
        <ModText>
          Concretamente, las Comunidades Autónomas han notificado al Ministerio
          de Sanidad que, hasta el 14 de mayo, han llevado a cabo un total de
          1.919.411 pruebas diagnósticas PCR. En cuanto al número de test
          rápidos realizados, la cifra asciente a 1.118.429.
        </ModText>
        <ModAnimation svg="/images/anim/02_abr_investigacion.svg" />

        <ModText>
          El Boletín Oficial del Estado ha publicado hoy la{" "}
          <InlineLink link="https://www.boe.es/boe/dias/2020/05/16/pdfs/BOE-A-2020-5088.pdf">
            Orden
          </InlineLink>{" "}
          del Ministerio de Sanidad que flexibiliza determinadas restricciones
          de ámbito nacional en aplicación de la Fase 2 del Plan para la
          Transición hacia una Nueva Normalidad. Entrará en vigor el próximo
          lunes y afecta a la isla de Formentera (Baleares) y las de La
          Graciosa, El Hierro y La Gomera (Canarias).
        </ModText>
        <ModText>Entre las medidas aprobadas, se contempla:</ModText>
        <ModDotList>
          La circulación por la provincia, isla o unidad territorial de
          referencia en grupos de máximo 15 personas, entre las que no se
          contabilizan las personas convivientes.
        </ModDotList>
        <ModDotList>
          La apertura de todos los establecimientos comerciales y espacios
          culturales con límite de aforo.
        </ModDotList>
        <ModDotList>
          Se reanuda la liga profesional deportiva sin público, pero con la
          asistencia de los medios de comunicación para su retransmisión.
        </ModDotList>
        <ModDotList>
          El uso de piscinas recreativas y playas está permitido manteniendo
          medidas de seguridad.
        </ModDotList>

        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/COVID-19_pruebas_diagnosticas_14_05_2020.pdf"
            name="Total pruebas diagnósticas hasta el 14 de mayo"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day64;
